import React, { useState } from 'react';
import { useRouter } from 'next/router';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Button,
  useToast,
} from '@chakra-ui/react';
import axios from 'axios';
import api from "../config/axios";

const ContactModal = ({ isOpen, onClose, addToCart, selectedItem, type }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const toast = useToast();
  const router = useRouter();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
  
    // Payload for the formData API
    const formData = {
      Name: name,
      Email: email,
      Mobile: phone,
      Message: message,
    };

    try {
      // Call the API to add the customer and get the response with customer_id
      const addCustomerResponse = await api.post("/add_customer", {
        customer_name: name,
        mob_no: phone,
        email_id: email,
      });
  
      const { customer_id } = addCustomerResponse.data;
  
      // Log customer_id and type
      console.log("Customer ID:", customer_id);
      console.log("Pricing Type:", type);
  
      // Log the URL and query parameters
      console.log("Navigating to:", {
        pathname: '/pricing',
        query: {
          customer_id,
          pricing: type,
        },
      });
  
      // Call the existing API to submit the form data
      await axios.post(
        'https://script.google.com/macros/s/AKfycbyQ0ZXvxtid26fxYhrcSaQyyXuba7bLryiahg1euFT5u5s5qZ5ZdcEKGkfo-9LQre8/exec',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
  
      setIsSubmitted(true);
  
      toast({
        title: "Form submitted.",
        description: "Your message has been sent successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
  
      // Redirect user to pricing page with customer_id and pricing type as query parameters
      router.push({
        pathname: '/pricing',
        query: {
          customer_id,
          pricing: type,
        },
      });
  
      onClose();
    } catch (error) {
      console.error("Error submitting form:", error);
      toast({
        title: "Submission failed.",
        description: "An error occurred while submitting the form.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>See pricing details</ModalHeader>
        <ModalCloseButton />
        <form onSubmit={handleSubmit}>
          <ModalBody>
            <FormControl id="name" isRequired mb={4}>
              <FormLabel>Name</FormLabel>
              <Input
                type="text"
                placeholder="Enter your name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </FormControl>
            <FormControl id="email" isRequired mb={4}>
              <FormLabel>Email</FormLabel>
              <Input
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </FormControl>
            <FormControl id="phone" isRequired mb={4}>
              <FormLabel>Phone</FormLabel>
              <Input
                type="tel"
                placeholder="Enter your phone number"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </FormControl>
            <FormControl id="message" mb={4}>
              <FormLabel>Message</FormLabel>
              <Textarea
                placeholder="Enter your message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              type="submit"
              isLoading={isSubmitting}
            >
              Submit
            </Button>
            <Button variant="ghost" onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default ContactModal;
