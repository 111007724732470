import Head from "next/head";
import React, { useEffect, useState } from "react";
import axios from "axios";
import ReqDemoModal from "../components/reqDemo";
import { useDisclosure } from "@chakra-ui/react";
import { useRouter } from "next/router";
import ContactModal from "../components/contactModal";
import {
  Box,
  Heading,
  Container,
  Text,
  Button,
  Stack,
  Icon,
  useColorModeValue,
  createIcon,
  SimpleGrid,
  Flex,
  Avatar,
  List,
  ListItem,
  ListIcon,
  VStack,
  Center,
  HStack,
  Input,
  Stat,
  StatLabel,
  StatNumber,
  chakra,
  Link,
} from "@chakra-ui/react";
import Image from "next/image";
import { BsPerson } from "react-icons/bs";
import { FiServer } from "react-icons/fi";
import { GoLocation } from "react-icons/go";
import { MdWorkOutline } from "react-icons/md";
import Footer from "../components/footer";
import Candiateimg from "../public/assets/c1.png";
import Candiateimg1 from "../public/assets/c2.png";
import Candiateimg2 from "../public/assets/c3.png";
import Candiateimg3 from "../public/assets/c4.png";
import Candiateimg4 from "../public/assets/robot.png";
import aicover from "../public/assets/AiCoverimage.png";
import Chatbot from "./chatbot";

const Feature = ({ title, text, image }) => {
  return (
    <Flex direction="column" alignItems="center">
      <Center w={16} h={16} rounded="full" bg="gray.100" mb={1}>
        {image}
      </Center>
      <Text fontWeight={600} textAlign="center">
        {title}
      </Text>
      <Text color="gray.600" textAlign="center">
        {text}
      </Text>
    </Flex>
  );
};

function StatsCard(props) {
  const { title, stat, icon } = props;
  return (
    <Stat
      px={{ base: 2, md: 4 }}
      py={"5"}
      shadow={"xl"}
      border={"1px solid"}
      borderColor={useColorModeValue("gray.800", "gray.500")}
      rounded={"lg"}
    >
      <Flex justifyContent={"space-between"}>
        <Box pl={{ base: 2, md: 4 }}>
          <StatLabel fontWeight={"medium"} isTruncated>
            {title}
          </StatLabel>
          <StatNumber fontSize={"2xl"} fontWeight={"medium"}>
            {stat}
          </StatNumber>
        </Box>
        <Box
          my={"auto"}
          color={useColorModeValue("gray.800", "gray.200")}
          alignContent={"center"}
        >
          {icon}
        </Box>
      </Flex>
    </Stat>
  );
}
export default function Home() {
  const [totalUser, setTotalUser] = useState(0);
  const [assessmentUser, setAssessmentUser] = useState(0);
  const [jobsPosted, setJobsPosted] = useState(0);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [isModalOpen, setModalOpen] = useState(false);
  const router = useRouter();

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  // const handleSeeDetailsClick = () => {
  //   router.push("/pricing");
  // };

  const handleSeeDetailsClick = (type) => {
    setModalOpen(type);
  };

  useEffect(() => {
    // Fetch data for "Total users" from your backend API
    axios
      .get("https://161.97.173.8:8000/get_total_users", {
        responseType: "json",
      })
      .then((response) => {
        const data = response.data;
        console.log("API Response for Total Users:", data);

        setTotalUser(data.total_users_count || 0);
      })
      .catch((error) => {
        console.error("Error fetching Total users:", error);
      });

    // Fetch data for "Assessments users" from your backend API
    axios
      .get("https://161.97.173.8:8000/count_Assessed_Professionals", {
        responseType: "json",
      })
      .then((response) => {
        const data = response.data;
        console.log("API Response for Total Assessments:", data);
        setAssessmentUser(data.user_id_count || 0);
      })
      .catch((error) => {
        console.error("Error fetching Trusted By:", error);
      });

    // Fetch data for "Jobs Posted" from your backend API
    axios
      .get("https://161.97.173.8:8000/total_job", {
        responseType: "json",
      })
      .then((response) => {
        const data = response.data;
        const totalJobs = data.total_jobs;
        console.log("API Response for Jobs Posted:", totalJobs);
        setJobsPosted(totalJobs || 0);
      })
      .catch((error) => {
        console.error("Error fetching Jobs Posted:", error);
      });
  }, []);

  <Head>
    <title>Aptofy | AI based recruitment </title>

    <link rel="canonical" href="https://aptofy.com/" key="canonical" />
    <link rel="canonical" href="https://www.aptofy.com/" key="canonical" />

    <link
      href="https://fonts.googleapis.com/css2?family=Caveat:wght@700&display=swap"
      rel="stylesheet"
    />
    <meta title="Aptofy | Best AI-Based Recruitment Company in India"></meta>
    <meta
      content="Discover the power of Aptofy, India's best AI-based recruitment company. 
          Streamline your hiring process with advanced technology and comprehensive solutions 
          tailored to your need"
      name="description"
    />
    {/* <meta
          content="Aptofy, 
                           recruitment solution,
                           recruit,
                           hire,
                           Ai based recruitment,
                           ai based job portal,
                           ai based recruitment platform,
                           aldi how to get hired,
                           bias employment,
                           bias-free hiring,
                           executive job search sites,
                           aptofy jobs,
                           aptofy ai recruitment,
                           aptofy ,
                           AI powered recruitment,
                           fast closure aptofy,
                           aptofy training,
                           aptofy test,
                           aptofy visualized test report,
                           aptofy candidate sourcing,
                           aptofy candidate evaluation,
                           aptofy ai candidate screening "
          name="keywords"
        /> */}

    <meta name="rating" CONTENT="General" />
    <meta content="all" name="Googlebot-Image" />
    <meta content="all" name="Slurp" />
    <meta content="all" name="Scooter" />
    <meta content="ALL" name="WEBCRAWLERS" />
    <meta name="revisit-after" CONTENT="2 days" />
    <meta name="robots" content=" ALL, index, follow" />
    <meta name="robots" content=" ALL, index, follow" />
    <meta name="distribution" content="Global" />
    <meta name="rating" content="Safe For All" />
    <meta name="language" content="English" />
    <meta http-equiv="window-target" content="_top" />
    <meta http-equiv="pics-label" content="for all ages" />
    <meta name="rating" content="general" />
    <meta content="All, FOLLOW" name="GOOGLEBOTS" />
    <meta content="All, FOLLOW" name="YAHOOBOTS" />
    <meta content="All, FOLLOW" name="MSNBOTS" />
    <meta content="All, FOLLOW" name="BINGBOTS" />

    {/* header icon  */}
    <link
      rel="icon"
      type="image/png"
      sizes="180x180"
      href="/assets/aptofy.png"
    />
    <link rel="icon" type="image/png" sizes="32x32" href="/assets/aptofy.svg" />
    <link rel="apple-touch-icon" sizes="180x180" href="/assets/aptofy.png" />
    <link rel="mask-icon" href="/static/favicon-mask.svg" color="#000000" />
  </Head>;

  return (
    <>
      <Chatbot />

      <Flex
        direction="column"
        align="center"
        py={{ base: 10, md: 24 }} // Reduced top padding
        textAlign="center"
        w="100%"
      >
        <Heading
          color="#3A56D4"
          fontWeight={600}
          fontSize={{ base: "xl", sm: "xl", md: "3xl" }}
          textAlign="center"
          mt={{ base: 4, md: 8 }} // Added margin-top to the heading
        >
          {/* AI powered <br />
          <Text as={"span"} color="#3B58D7">
            recruitment for you
          </Text> */}

          <Text>
            Aptofy: The Cost-Effective AI Bot Revolutionizing Talent Acquisition
            with Unmatched Speed and Efficiency
          </Text>
        </Heading>

        <Flex
          direction={{ base: "column", md: "row" }}
          align="center"
          mt={{ base: 10, md: 16 }}
          w="100%"
          maxW="100vw"
          h="50vh"
        >
          <Box
            flexShrink={0}
            flex={{ base: "1", md: "1 1 50%" }}
            textAlign="left"
            height="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Image src={aicover} width="100%" height="100%" objectFit="cover" />
          </Box>
          <Box
            ml={{ base: 0, md: 6 }}
            textAlign="left"
            flex={{ base: "1", md: "1 1 50%" }}
            height="100%"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            width="100%"
          >
            <ReqDemoModal isOpen={isOpen} onClose={onClose} />
            <Text mb={4} width="80%" fontSize={{ base: "md", md: "xl" }}>
              Discover top talent like never before with Aptofy, the
              cutting-edge AI-powered recruitment solution. Aptofy goes beyond
              traditional candidate screening and evaluation to revolutionise
              the hiring process from start to finish.
            </Text>
            <Stack
              direction={{ base: "column", md: "row" }}
              spacing={4} // Add spacing between buttons
            >
              <Link href="/AboutUs">
                <Button
                  bg="#3A56D4"
                  _hover={{
                    bg: "#2A46B0",
                  }}
                  color="white"
                  width={{ base: "100%", md: "auto" }}
                >
                  Learn More
                </Button>
              </Link>
              <Button
                onClick={onOpen}
                color={"white"}
                bg="#3B58D7"
                mb={4}
                width={{ base: "100%", md: "auto" }}
                _hover={{
                  bg: "#2A46B0",
                }}
              >
                Request Demo
              </Button>
            </Stack>
          </Box>
        </Flex>
      </Flex>

      {/* What We Offer */}
      <Box p={2}>
        {" "}
        <Center>
          <Heading
            as="h1"
            fontSize={{ base: "24px", md: "28px" }}
            textAlign="center"
            color="#3A56D4"
            // textDecoration="underline"
          >
            What We Offer
          </Heading>
        </Center>
        <Text textAlign="center" mt={4}>
          {" "}
          Unlock your organisation's true potential with Aptofy, the AI-powered
          recruitment solution that revolutionises the way you find, assess, and
          hire top talent. Say goodbye to tedious processes and welcome an
          efficient, accurate, and reliable solution that takes your recruitment
          strategy to the next level.
        </Text>
      </Box>
      <SimpleGrid
        columns={[1, null, 2]}
        spacing="40px"
        p="20px"
        justifyItems="center"
      >
        {/* Box 1 */}
        <Box
          // fontFamily="Arial, sans-serif"
          border="1px solid"
          borderColor="gray.200"
          borderRadius="md"
          p="20px"
          // background="linear-gradient(to right, #f5f5f5, #ffffff)"
          boxShadow="md"
          textAlign="left" // Align text to left for better readability
        >
          <Heading
            as="h3"
            size="lg"
            mb="10px"
            color="#3A56D4"
            // fontFamily="Helvetica"
          >
            Discover Your Ideal Talent with Aptofy
          </Heading>
          <Text mb="20px">
            Aptofy is your dedicated Talent Acquisition Specialist, meticulously
            screening candidates and delivering comprehensive evaluations. With
            Aptofy, obtaining high-quality candidate profiles that perfectly
            match your requirements is no longer a dream but a reality.
          </Text>
          <Text mb="20px">
            Our AI-driven bot conducts interviews for you, ensuring that every
            assessment is tailored to your specific needs. We provide detailed
            reports on verbal, non-verbal, and written communication skills.
            Make informed decisions, build exceptional teams, and fulfill your
            requirements with confidence.
          </Text>
          <Text mb="20px">
            Experience the future of talent acquisition with Aptofy Today!
          </Text>

          <Button
            color={"white"}
            bg="#3B58D7"
            onClick={() => handleSeeDetailsClick(1)}
            _hover={{
              bg: "#2A46B0",
            }}
          >
            See Details
          </Button>
        </Box>

        {/* Box 2 */}
        <Box
          // fontFamily="Arial, sans-serif"
          border="1px solid"
          borderColor="gray.200"
          borderRadius="md"
          p="20px"
          // background="linear-gradient(to right, #ffffff, #f5f5f5)"
          boxShadow="md"
          textAlign="left" // Align text to left for better readability
        >
          <Heading as="h3" size="lg" mb="10px" color="#3A56D4">
            Interview as a Service with Aptofy - The AI bot
          </Heading>
          <Text
            mb="20px"
            //  fontFamily="Merriweather Sans"
          >
            Aptofy is your expert ally, conducting interviews and providing
            comprehensive evaluations. Assess your candidates with accuracy,
            consistency, and speed, all while eliminating bias.
          </Text>
          <Text mb="20px">
            Aptofy evaluates candidates using a range of objective questions,
            coding challenges, and interview scenarios. We assess situational
            and problem-solving abilities, as well as communication and other
            essential soft skills.
          </Text>
          <Text mb="20px">
            Discover the power of precise, fair, and efficient candidate
            assessments with Aptofy.
          </Text>
          <Text mb="20px">
            We specialize in niche domains, offering customizable evaluations
            tailored to fit your organization's unique needs.
          </Text>
          <Button
            color={"white"}
            bg="#3B58D7"
            onClick={() => handleSeeDetailsClick(2)}
            _hover={{
              bg: "#2A46B0",
            }}
          >
            See Details
          </Button>
        </Box>

        {/* Box 3 */}
        <Box
          // fontFamily="Arial, sans-serif"
          border="1px solid"
          borderColor="gray.200"
          borderRadius="md"
          p="20px"
          gridColumn={["1", null, "span 2"]}
          // background="linear-gradient(to bottom, #ffffff, #f5f5f5)"
          boxShadow="md"
          maxWidth="635px"
          width="100%"
          textAlign="left" // Align text to left for better readability
        >
          <Heading as="h3" size="lg" mb="10px" color="#3A56D4">
            Elevate Your Training Institute with AI-Powered Proctored
            Assessments
          </Heading>
          <Text mb="20px">
            Transform your training institute with our AI-powered proctored
            assessments, designed for secure, efficient, and flexible
            evaluations. Our advanced AI technology handles and monitors
            assessments, freeing trainers from invigilation, checking, and
            allowing automatic certificate generation upon completion. Trainees
            enjoy the flexibility of phygital training, taking exams at their
            convenience. Aptofy’s training interviews and global placement
            assistance boost employability, making your local institute a global
            contender. This solution streamlines operations, reduces
            administrative tasks, and supports growth, helping your institute
            reach new heights. Embrace the future of training with our
            cutting-edge technology and revolutionize your certification process
            today.
          </Text>
          <Text mb="20px">
            We are easy to integrate, secure, and protect your intellectual
            property. We help you brand yourself.
          </Text>
          <Button
            color={"white"}
            bg="#3B58D7"
            onClick={() => handleSeeDetailsClick(3)}
            _hover={{
              bg: "#2A46B0",
            }}
          >
            See Details
          </Button>
        </Box>
      </SimpleGrid>
      {/* company data user */}
      <Box
        maxW="7xl"
        mx={"auto"}
        pt={5}
        px={{ base: 2, sm: 12, md: 17 }}
        mt={50}
      >
        <chakra.h1
          textAlign={"center"}
          fontSize={"4xl"}
          py={10}
          fontWeight={"bold"}
          color="#3B58D7"
        >
          Join the growth wave! Amplify your company's success with ours
        </chakra.h1>
        {/* <Text textAlign={'center'} fontSize={'xl'} py={2}>
      instead of Users  - Trusted By , Assessed Professionals , countries
      </Text><br/> */}
        <SimpleGrid columns={{ base: 1, md: 3 }} spacing={{ base: 5, lg: 8 }}>
          <StatsCard
            title={"Trusted By"}
            stat={totalUser}
            icon={<BsPerson size={"3em"} />}
          />
          <StatsCard
            title={"Assessed Professionals"}
            stat={assessmentUser}
            icon={<FiServer size={"3em"} />}
          />
          <StatsCard
            title={"Opportunities"}
            stat={jobsPosted}
            icon={<MdWorkOutline size={"3em"} />}
          />
        </SimpleGrid>
      </Box>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <Footer />
      {/* Contact Modal */}
      <ContactModal
        isOpen={isModalOpen}
        onClose={closeModal}
        type={isModalOpen}
      />
    </>
  );
}
